import React, { } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import ResetPasswordForm from "../components/auth/ResetPasswordForm";

function ResetPassword() {




  return (
    <>

      <div className="h-full">

        <Header />
        <ResetPasswordForm />
        <Footer />


      </div>
    </>
  );
}

export default ResetPassword