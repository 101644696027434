import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from "../common/loader";

function Question({ onSubmit, logout }) {
  const [questionData, setQuestionData] = useState({
    question: "",
    user_age: "",
    user_gender: "",
    problem_duration: ""
  });

  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) navigate("/");
  }, [logout, navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setQuestionData({
      ...questionData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const validate = () => {
    const errors = {};

    if (!questionData.question) {
      errors.question = "Question is required";
    }

    if (!questionData.user_age) {
      errors.user_age = "Age is required";
    }

    if (!questionData.user_gender) {
      errors.user_gender = "Gender is required";
    }

    if (!questionData.problem_duration) {
      errors.problem_duration = "Problem duration is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleQuestion = async (event) => {
    event.preventDefault();

    if (!validate()) {
      setError('All fields are required');
      return;
    }

    try {
      setLoader(true);

      const token = localStorage.getItem('access_token');
      const response = await axios.post('https://app.sapius.ai/api/chat/query', questionData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const questionResponseData = response.data;
      onSubmit(questionData, questionResponseData);

      setLoader(false);
    } catch (error) {
      setError('An error occurred while submitting the form');
      setLoader(false);
    }
  };

  return (
    <div className="h-full">
      <div className="mainContainer p-6 pt-10 lg:pt-24 relative">
        {loader ? (<Loader />) : ""}
        <div className="container mx-auto mt-12">
          <h1 className="text-2xl sm:text-3xl lg:text-4xl !leading-tight max-w-4xl mx-auto font-bold mb-3 sm:mb-4">Personalized AI for medical assessment, treatment and prevention.</h1>
          <p className="text-md sm:text-xl max-w-lg mx-auto mb-8">Check your symptoms and treatment using Sapius personal health bot.</p>

          <div className="relative w-full mx-auto max-w-xl text-left">
            <form onSubmit={handleQuestion}>
              <div className="flex gap-5 w-full flex-col sm:flex-row">
                <div className="flex flex-col w-1/4">
                  <label className="label mb-2">Age</label>
                  <div className={`flex flex-row bg-white items-center  min-h-12  w-full border-l-4 py-1 px-5 rounded-md sm:mb-5 ${validationErrors.user_age ? 'border-red-500' : 'border-primary'}`}>
                    <input
                      type="number"
                      className="rounded-md px-3 w-full sm:w-full py-2 outline-none placeholder-gray-500 text-left font-semibold"
                      placeholder="18"
                      name="user_age"
                      value={questionData.user_age}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="flex flex-col w-3/4">
                  <label className="pt-4 sm:pt-0 mb-2 label">Gender</label>

                  <div className={`flex flex-col sm:flex-row min-h-12 sm:items-center bg-white w-full border-l-4 py-1 px-5 rounded-md mb-5 ${validationErrors.user_gender ? 'border-red-500' : 'border-primary'}`}>
                    <div className="flex flex-col sm:flex-row my-5 sm:my-0 sm:justify-center sm:items-center gap-5">
                      <div className="flex items-center">
                        <input
                          value="Male"
                          onChange={handleChange}
                          id="Male"
                          type="radio"
                          name="user_gender"
                          checked={questionData.user_gender === "Male"}
                          className="w-6 h-6 text-blue-600 bg-gray-100 border-primary-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label htmlFor="Male" className="ms-2 text-sm font-semibold text-gray-900 dark:text-gray-300">
                          Male
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          value="Female"
                          onChange={handleChange}
                          id="Female"
                          type="radio"
                          name="user_gender"
                          checked={questionData.user_gender === "Female"}
                          className="w-6 h-6 text-blue-600 bg-gray-100 border-primary-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label htmlFor="Female" className="ms-2 text-sm font-semibold text-gray-900 dark:text-gray-300">
                          Female
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          value="Other"
                          onChange={handleChange}
                          id="Other"
                          type="radio"
                          name="user_gender"
                          checked={questionData.user_gender === "Other"}
                          className="w-6 h-6 text-blue-600 bg-gray-100 border-primary-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label htmlFor="Other" className="ms-2 text-sm font-semibold text-gray-900 dark:text-gray-300">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-full">
                <label className="pt-4 sm:pt-0 mb-2 label">Please provide a detailed description of your symptoms below. </label>
                <div className={`flex relative bg-white border-l-4 rounded-md mb-4 ${validationErrors.question ? 'border-red-500' : 'border-primary'}`}>
                  <textarea
                    name="question"
                    value={questionData.question}
                    onChange={handleChange}
                    type="text"
                    className="rounded-md min-h-20 py-2 px-5 w-full outline-none placeholder-gray-500"
                    placeholder="Ex. I have cough and mild fever."
                  />
                </div>
              </div>

              <div className="flex flex-col w-full">
                <label className="pt-4 sm:pt-0 mb-2 label">Select Duration of Symptoms?</label>
                <select
                  className={`border-l-4 rounded-md mb-5 outline-none bg-white w-full py-4 px-5 ${validationErrors.problem_duration ? 'border-red-500' : 'border-primary'}`}
                  name="problem_duration"
                  value={questionData.problem_duration}
                  onChange={handleChange}
                >
                  {/* <option value="">Select duration</option> */}
                  <option value="0 - 3 days">0 - 3 days</option>
                  <option value="3 - 7 days">3 - 7 days</option>
                  <option value="More than a week">More than a week</option>
                  <option value="More than 1 months">More than 1 months </option>
                  <option value="More than 6 months">More than 6 months </option>
                  <option value="More than 1 year">More than 1 year</option>
                </select>
              </div>

              {error && <p className="error mt-2">{error}</p>}

              <button type="submit" className="text-lg font-semibold bg-primary text-gray-700 rounded-md px-6 py-2 block hover:bg-primary-700 m-auto mt-2 mb-4">
                Ask Sapius Chatbot
              </button>
            </form>

            <p className="text-center italic text-gray-400">Remember, this is for informational purposes only and cannot replace a professional medical diagnosis.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Question;
