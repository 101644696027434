import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';
import Chatbot from './pages/Chatbot';
import VerifyEmail from './pages/VerifyEmail';
import Bot from './pages/Bot';
import Authentication from './components/auth/Authentication';
import MyProfile from './pages/MyProfile';

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/:referralCode" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:resetPWToken" element={<ResetPassword />} />
        <Route path="/verify-email/:verifyEmailToken" element={<VerifyEmail />} />
        <Route path="/chat" element={<Authentication Component={Chatbot} />} />
        <Route path="/bot" element={<Authentication Component={Bot} />} />
        <Route path="/myprofile" element={<Authentication Component={MyProfile} />} />
        <Route path="/change-password" element={<Authentication Component={ChangePassword} />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
