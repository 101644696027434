import React, { } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import LoginForm from "../components/auth/LoginForm";

function Login() {




  return (
    <>

      <div className="h-full">

        <Header />

        <LoginForm />

        <Footer />


      </div>
    </>
  );
}

export default Login