import React, { } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import LoginForm from "../components/auth/LoginForm";

function Bot() {




    return (
        <>

            <div className="h-full">

                <Header />


                <div className="mainContainer py-6 pt-24 relative z-0">
                    <div className="botContainer mt-2">

                        <div className="tabBlock border-b border-gray-300">
                            <ul className="flex flex-wrap -mb-px text-md font-semibold text-center text-gray-500 justify-center">
                                <li className="me-2">
                                    <a href="#" className="inline-flex gap-1 items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-primary hover:border-primary tabActive group">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                                        </svg>
                                        Sapius Chat
                                    </a>
                                </li>
                                <li className="me-2">
                                    <a href="#" className="inline-flex gap-1 items-center justify-center p-4 text-gray-600  rounded-t-lg hover:text-primary hover:border-primary group" aria-current="page">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                                        </svg>
                                        Health Summary
                                    </a>
                                </li>

                            </ul>
                        </div>


                        <div className="TabContent max-w-screen-lg border-2 p-1 m-auto relative">

                            <div className="botAnswers min-h-80">
                                Ansuesr
                            </div>

                            <div className="botQuestions m-auto mb-5 bottom-0">
                                <h3>For more information after the diagnosis, please click the buttons below:</h3>
                                <div className="flex mt-5 gap-3 justify-center">
                                    <button className="text-sm text-gray-700 font-semibold  border-l-4 border-primary bg-primary-200  w-1/3 text-left rounded-md px-4 py-2 block hover:text-white hover:bg-primary">
                                        Treatment Options
                                    </button>
                                    <button className="text-sm text-gray-700 font-semibold  border-l-4 border-primary bg-primary-200  w-1/3 text-left rounded-md px-4 py-2 block hover:text-white hover:bg-primary">
                                        FDA approved Medications
                                    </button>
                                    <button className="text-sm text-gray-700 font-semibold  border-l-4 border-primary bg-primary-200  w-1/3 text-left rounded-md px-4 py-2 block hover:text-white hover:bg-primary">
                                        Summarize the Session
                                    </button>
                                </div>
                                <div className="mt-8">
                                    <h3 className="mb-2">You can enter more information or ask me anything:</h3>
                                    <textarea className="rounded-md w-full h-16 border-l-4 border-primary p-3 outline-none"></textarea>
                                </div>
                            </div>

                        </div>





                    </div>
                </div>


                <Footer />


            </div>
        </>
    );
}

export default Bot