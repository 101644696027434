import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { UserContext } from '../context/UserContext';
import ChangePasswordForm from "../components/auth/ChangePasswordForm";

function MyProfile() {
  const navigate = useNavigate();
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const user_nid = userDetails?.nid;
  const [profileImage, setProfileImage] = useState();

  useEffect(() => {
    setProfileImage(`https://app.sapius.ai/api/profile/view/${user_nid}`);
  }, [user_nid]);

  const handleChangePWClick = () => {
    setIsChangePassword(!isChangePassword);
  };



  return (
    <>
      <div className="h-full">
        <Header />
        <div className="h-full">
          <div className="mainContainer py-6 pt-24 relative z-0">
            <div className="container mx-auto max-w-4xl">
              <div className="myProfile">
                <h1 className="text-center font-semibold text-3xl mb-8 text-primary mt-10">Change Password</h1>

                {successMessage && (
                  <div className="text-green font-semibold px-4 py-3 relative" role="alert">
                    <span className="block sm:inline">{successMessage}</span>
                  </div>
                )}


                <div className="flex gap-6 mt-5">
                  <div className="bg-white w-1/3 rounded-md px-3 py-10 items-center text-center">
                    <div className="profileImage relative items-center w-36 h-36 mx-auto group mb-6">
                      <img src={profileImage} className="rounded-full w-36 h-36 " />

                    </div>
                    <p className="mt-6">{userDetails?.firstname}</p>
                    <p>{userDetails?.email}</p>

                    <p className="mt-10 text-primary hover:underline">
                      <Link to="/myprofile" >My Profile</Link>
                    </p>

                  </div>


                  <div className="bg-white w-2/3 rounded-md pt-8">

                    <ChangePasswordForm handleChangePWClick={handleChangePWClick} />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default MyProfile;
