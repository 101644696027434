import React, { useState, useEffect } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

function VerifyEmail() {
  const navigate = useNavigate();
  const { verifyEmailToken } = useParams();
  const [verificationStatus, setVerificationStatus] = useState('pending');

  useEffect(() => {
    const verifyEmail = async () => {
      if (!verifyEmailToken) {
        setVerificationStatus('failed');
        return;
      }

      try {
        const response = await axios.post('https://app.sapius.ai/api/register/verify', {
          verification_code: verifyEmailToken
        });

        console.error('Verification response >>>>:', response.data);
        console.error('verifyEmailToken >>>>:', verifyEmailToken);

        setTimeout(() => navigate('/'), 10000); // Redirect to chat page on successful registration after 10 seconds


        if (response.data.success) {
          setVerificationStatus('success');
        } else {
          setVerificationStatus('failed');
        }
      } catch (error) {
        console.error('Verification failed:', error);
        setVerificationStatus('failed');
      }
    };

    verifyEmail();
  }, [verifyEmailToken]);

  return (
    <>
      <div className="h-full">
        <Header />
        <div className="mainContainer py-6 pt-24 relative z-0">
          <div className="loginContainer mt-16">
            <div className="mx-auto max-w-md text-center rounded-md">
              {verificationStatus === 'pending' && (
                <h1 className="text-center font-semibold text-3xl mb-8 text-primary">Verifying your email...</h1>
              )}
              {verificationStatus === 'success' && (
                <>
                  <h1 className="text-center font-semibold text-3xl mb-8 text-primary">We have successfully verified your email</h1>
                  <p>Please login with your credentials.</p>
                  <label className="block">
                    <Link to="/" className="text-lg w-24 m-auto mt-5 font-semibold bg-primary text-white rounded-md px-6 py-2 block hover:bg-primary-700">
                      <span>Login</span>
                    </Link>
                  </label>
                </>
              )}
              {verificationStatus === 'failed' && (
                <>
                  <h1 className="text-center font-semibold text-3xl mb-8 text-primary">Email verification failed</h1>
                  <p>There was an error verifying your email. Please try again or contact support.</p>
                </>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default VerifyEmail;
