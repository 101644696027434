import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userDetails, setUserDetailsState] = useState(null);

    // Function to set userDetails in both state and localStorage
    const setUserDetails = (details) => {
        setUserDetailsState(details);
        if (details) {
            localStorage.setItem('userDetails', JSON.stringify(details));
        } else {
            localStorage.removeItem('userDetails');
        }
    };

    // Load userDetails from localStorage when the component mounts
    useEffect(() => {
        const storedUserDetails = localStorage.getItem('userDetails');
        if (storedUserDetails) {
            setUserDetailsState(JSON.parse(storedUserDetails));
        }
    }, []);

    return (
        <UserContext.Provider value={{ userDetails, setUserDetails }}>
            {children}
        </UserContext.Provider>
    );
};
