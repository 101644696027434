import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from "../common/loader";

function ForgotPasswordForm() {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  const validate = () => {
    const errors = {};

    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleForgotpw = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }


    try {
      setLoader(true);
      const response = await axios.post('https://app.sapius.ai/api/auth/forgot', {
        email: email,
      });

      if (response.data.success) {
        const user_id = response.data.user_id;
        console.log('forgot password response user_id>>>>', user_id);

        setLoader(false);
        setSuccess("Password reset verification link have been sent to your email.");
        setError('');
        setTimeout(() => navigate('/'), 10000);
      } else {
        setLoader(false);
        setError(response.data.message || "An error occurred while sending the password reset instructions. Please try again.");
        setSuccess('');
      }
    } catch (error) {
      setLoader(false);
      setError("An error occurred while sending the password reset instructions. Please try again.");
      setSuccess('');
    }
  };

  return (
    <>
      {loader ? (<Loader />) : ("")}
      <div className="mainContainer py-6 pt-24 relative z-0">

        <div className="loginContainer mt-16">

          {success ? (

            <div className="mainContainer py-6 pt-24 relative z-0">

              <div className="loginContainer mt-16">
                <div className="mx-auto max-w-md text-center rounded-md">

                  <h1 className="text-center font-semibold text-3xl mb-4 text-primary">Request sent successfully</h1>
                  <p> We've sent to you an email with the password reset link <b className="block m-2"> {email}</b></p>
                  <p>Didn't receive the email? Check your spam folder.</p>

                  <label className="block mt-4">
                    <Link to="/" className="text-lg w-48 m-auto mt-5 font-semibold bg-primary text-white rounded-md px-6 py-2 block hover:bg-primary-700">
                      <span>Back to Login</span>
                    </Link>
                  </label>

                </div>
              </div>
            </div>
          ) :
            (
              <div className="mx-auto max-w-md text-left rounded-md">
                <h1 className="text-center font-semibold text-3xl mb-8 text-primary">Forgot Password</h1>
                <p>Please enter the email address to reset your password.</p>
                <form onSubmit={handleForgotpw}>
                  <div className={`flex relative bg-white border-l-4 rounded-md mt-5  ${validationErrors.email ? 'border-red-500' : 'border-primary'}`}>
                    <input
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="block px-4 pt-5 pb-2 w-full text-md bg-transparent rounded-md appearance-none outline-none peer"
                      placeholder=""
                      id="EmailAddress"
                    />

                    <label for="EmailAddress" className="absolute text-md  text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-4 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2">Email Address</label>

                  </div>
                  {validationErrors.email && <p className="error mt-2">{validationErrors.email}</p>}

                  {error && <p className="error mt-3">{error}</p>}
                  <div className="flex justify-between flex-col items-center mt-8">
                    <button type="submit" className="text-lg w-full mb-5 font-semibold bg-primary text-white rounded-md px-6 py-2 block hover:bg-primary-700">
                      Submit
                    </button>
                    <label className="block">
                      <Link to="/" className="cursor-pointer w-full items-center text-primary font-medium hover:underline">
                        <span>Back to Login</span>
                      </Link>
                    </label>
                  </div>
                </form>
              </div>

            )}
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordForm;
