import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from "../common/loader";

function RegisterForm() {
  const { referralCode } = useParams();
  const [loader, setLoader] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [success, setSuccess] = useState('');
  const [referral, setReferral] = useState(referralCode || '');
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("access_token")) navigate("/chat");
  }, [navigate]);

  const validate = () => {
    const errors = {};

    if (!firstname) errors.firstname = "First Name is required";
    if (!lastname) errors.lastname = "Last Name is required";
    if (!mobile) errors.mobile = "Mobile Number is required";
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }
    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    } else if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      setError("Please fill the highlighted fields.");
    } else {
      setError("");
    }

    return Object.keys(errors).length === 0;
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }

    try {
      setLoader(true);
      const response = await axios.post('https://app.sapius.ai/api/register/new', {
        firstname: firstname,
        lastname: lastname,
        email: email,
        mobile: mobile,
        password: password,
        confirm_password: confirmPassword,
        referral: referral
      });

      console.log('Registration successful', response);
      setLoader(false);
      setSuccess('Registration successful! Redirecting to chat...');
      setTimeout(() => navigate('/'), 10000); // Redirect to chat page on successful registration after 10 seconds
    } catch (error) {
      setError('There is already an account with the email address, Please use a different email address');
      setLoader(false);
      console.error('Registration failed error!', error);
    }
  };

  return (
    <>
      {loader && <Loader />}

      {success ? (
        <div className="mainContainer py-6 pt-24 relative z-0">
          <div className="loginContainer mt-16">
            <div className="mx-auto max-w-lg text-center rounded-md">
              <h1 className="text-center font-semibold text-3xl mb-4 text-primary">You have registered successfully</h1>
              <p>Email verification link has been sent to <b className="block m-2">{email}</b></p>
              <p>Didn't receive the email? Check your spam folder.</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="mainContainer py-6 pt-24 relative z-0">
          <div className="registerContainer mt-16">
            <div className="mx-auto max-w-xl text-left rounded-md">
              <h1 className="text-center font-semibold text-3xl mb-8 text-primary">Register</h1>
              <form onSubmit={handleRegister}>
                <div className="flex gap-4">
                  <div className={`flex relative w-2/4 bg-white border-l-4 rounded-md mt-5 ${validationErrors.firstname ? 'border-red-500' : 'border-primary'}`}>
                    <input
                      name="firstname"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      type="text"
                      className="block px-4 pt-5 pb-2 w-full text-md bg-transparent rounded-md appearance-none outline-none peer"
                      placeholder=""
                      id="FirstName"
                    />
                    <label htmlFor="FirstName" className="absolute text-md  text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-4 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2">First Name</label>
                  </div>

                  <div className={`flex relative w-2/4 bg-white border-l-4 rounded-md mt-5 ${validationErrors.lastname ? 'border-red-500' : 'border-primary'}`}>
                    <input
                      name="lastname"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      type="text"
                      className="block px-4 pt-5 pb-2 w-full text-md bg-transparent rounded-md appearance-none outline-none peer"
                      placeholder=""
                      id="Last Name"
                    />
                    <label htmlFor="Last Name" className="absolute text-md  text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-4 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2">Last Name</label>
                  </div>
                </div>

                <div className="flex gap-4">
                  <div className={`flex relative w-full bg-white border-l-4 rounded-md mt-5 ${validationErrors.email ? 'border-red-500' : 'border-primary'}`}>
                    <input
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      className="block px-4 pt-5 pb-2 w-full text-md bg-transparent rounded-md appearance-none outline-none peer"
                      placeholder=""
                      id="EmailAddress"
                    />
                    <label htmlFor="EmailAddress" className="absolute text-md  text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-4 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2">Email Address</label>
                  </div>


                </div>


                <div className="flex gap-4">

                  <div className={`flex relative w-2/4 bg-white border-l-4 rounded-md mt-5 ${validationErrors.mobile ? 'border-red-500' : 'border-primary'}`}>
                    <input
                      name="mobile"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      type="text"
                      className="block px-4 pt-5 pb-2 w-full text-md bg-transparent rounded-md appearance-none outline-none peer"
                      placeholder=""
                      id="Mobile"
                    />
                    <label htmlFor="Mobile" className="absolute text-md  text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-4 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2">Mobile</label>
                  </div>

                  <div className="flex relative w-2/4 bg-white border-l-4 rounded-md mt-5 border-primary">
                    <input
                      name="referal"
                      value={referral}
                      onChange={(e) => setReferral(e.target.value)}
                      type="text"
                      className="block px-4 pt-5 pb-2 w-full text-md bg-transparent rounded-md appearance-none outline-none peer"
                      placeholder=""
                      id="Referal"
                    />
                    <label htmlFor="Referal" className="absolute text-md  text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-4 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2">Referal Code (Optional)</label>
                  </div>

                </div>

                <div className="flex gap-4">
                  <div className={`flex relative w-2/4 bg-white border-l-4 rounded-md mt-5 ${validationErrors.password ? 'border-red-500' : 'border-primary'}`}>
                    <input
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      className="block px-4 pt-5 pb-2 w-full text-md bg-transparent rounded-md appearance-none outline-none peer"
                      placeholder=""
                      id="Password"
                    />
                    <label htmlFor="Password" className="absolute text-md  text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-4 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2">Password</label>
                  </div>

                  <div className={`flex relative w-2/4 bg-white border-l-4 rounded-md mt-5 ${validationErrors.confirmPassword ? 'border-red-500' : 'border-primary'}`}>
                    <input
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type="password"
                      className="block px-4 pt-5 pb-2 w-full text-md bg-transparent rounded-md appearance-none outline-none peer"
                      placeholder=""
                      id="ConfirmPassword"
                    />
                    <label htmlFor="ConfirmPassword" className="absolute text-md  text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-4 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2">Confirm Password</label>
                  </div>
                </div>





                {error && <p className="error mt-2 text-red-500">{error}</p>}

                <div className="flex justify-between items-center mt-8">
                  <button type="submit" className="text-lg font-semibold w-auto mx-auto bg-primary text-white rounded-md px-6 py-2 block hover:bg-primary-700">
                    Register
                  </button>
                </div>
              </form>

              <div className="mx-auto max-w-md text-center">
                <div className="flex justify-center items-center my-8">
                  <p>Already have an account?</p>
                  <Link to="/" className="cursor-pointer text-primary font-medium ml-2 hover:underline">
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RegisterForm;
