import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from "../common/loader";

function ResetPasswordForm() {
  const [loader, setLoader] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const { resetPWToken } = useParams();
  const [verificationStatus, setVerificationStatus] = useState('pending');


  const validateToken = () => {
    const errors = {};

    if (!newPassword) {
      errors.newPassword = "Password is required";
    } else if (newPassword.length < 6) {
      errors.newPassword = "Password must be at least 6 characters";
    }

    if (!confirmNewPassword) {
      errors.confirmNewPassword = "Password is required";
    } else if (confirmNewPassword.length < 6) {
      errors.confirmNewPassword = "Password must be at least 6 characters";
    } else if (confirmNewPassword !== newPassword) {
      errors.confirmNewPassword = "Passwords do not match";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
  const verifyFPWToken = async () => {
    if (!resetPWToken) {
      setVerificationStatus('failed');
      return;
    }

    try {
      setLoader(true);
      const response = await axios.post('https://app.sapius.ai/api/auth/verify_code', {
        verification_code: resetPWToken,
      });
      setLoader(false);
      console.error('VPToken response >>>>:', response.data);
      setVerificationStatus('success');
    } catch (error) {
      setLoader(false);
      setVerificationStatus('failed');
      setError("Invalid or expired verification code. Please try again.");
    }
  };
    verifyFPWToken();
  }, [resetPWToken]);

  const handleChangePassword = async (event) => {
    event.preventDefault();
    if (!validateToken()) {
      return;
    }

    if (verificationStatus !== 'success') {
      setError("Please wait for verification to complete or try again.");
      return;
    }

    try {
      setLoader(true);
      const response = await axios.post('https://app.sapius.ai/api/auth/change_password', {
        verification_code: resetPWToken,
        // user_id: '1',
        password: newPassword,
        confirm_password: confirmNewPassword
      });

      setLoader(false);
      setSuccess("Password reset successfully.");
      setError('');
    } catch (error) {
      setLoader(false);
      setError("An error occurred while changing the password. Please try again.");
    }
  };

  return (
    <>
      {loader ? (<Loader />) : ("")}
      <div className="mainContainer py-6 pt-24 relative z-0">

        <div className="loginContainer mt-16">

          {success ? (

            <div className="mainContainer py-6 pt-24 relative z-0">

              <div className="loginContainer mt-16">
                <div className="mx-auto max-w-md text-center rounded-md">

                  <h1 className="text-center font-semibold text-3xl mb-4 text-primary">Your Password Updated successfully</h1>
                  <p>Login your account by using credentials </p>

                  <label className="block mt-4">
                    <Link to="/" className="text-lg w-24 m-auto mt-5 font-semibold bg-primary text-white rounded-md px-6 py-2 block hover:bg-primary-700">
                      <span>Login</span>
                    </Link>
                  </label>

                </div>
              </div>
            </div>
          ) :

            (

              <div className="mx-auto max-w-md text-left rounded-md">
                <h1 className="text-center font-semibold text-3xl mb-8 text-primary">Reset Password</h1>
                <p>Please enter new password</p>
                <form onSubmit={handleChangePassword}>
                  <div className={`flex relative bg-white border-l-4 rounded-md mt-5  ${validationErrors.newPassword ? 'border-red-500' : 'border-primary'}`}>
                    <input
                      name="newPassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      type="password"
                      className="block px-4 pt-5 pb-2 w-full text-md bg-transparent rounded-md appearance-none outline-none peer"
                      placeholder=""
                      id="NewPassword"
                    />
                    <label for="NewPassword" className="absolute text-md  text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-4 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2">New Password</label>
                  </div>
                  {validationErrors.newPassword && <p className="error mt-2">{validationErrors.newPassword}</p>}
                  <div className={`flex relative bg-white border-l-4 rounded-md mt-5  ${validationErrors.confirmNewPassword ? 'border-red-500' : 'border-primary'}`}>
                    <input
                      name="confirmNewPassword"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                      type="password"
                      className="block px-4 pt-5 pb-2 w-full text-md bg-transparent rounded-md appearance-none outline-none peer"
                      placeholder=""
                      id="ConfirmPassword"
                    />

                    <label for="ConfirmPassword" className="absolute text-md  text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-4 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2">Confirm Password</label>

                  </div>
                  {validationErrors.confirmNewPassword && <p className="error mt-2">{validationErrors.confirmNewPassword}</p>}


                  {error && <p className="error mt-3">{error}</p>}
                  <div className="flex justify-between flex-col items-center mt-8">
                    <button type="submit" className="text-lg w-full mb-5 font-semibold bg-primary text-white rounded-md px-6 py-2 block hover:bg-primary-700">
                      Submit
                    </button>
                    <label className="block">
                      <Link to="/" className="cursor-pointer w-full items-center text-primary font-medium hover:underline">
                        <span>Back to Login</span>
                      </Link>
                    </label>
                  </div>

                </form>
              </div>


            )}







        </div>
      </div >
    </>
  );
}

export default ResetPasswordForm;
