import React, { } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import RegisterForm from "../components/auth/RegisterForm";

function Register() {




  return (
    <>

      <div className="h-full">

        <Header />
        <RegisterForm />

        <Footer />


      </div>
    </>
  );
}

export default Register