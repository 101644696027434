import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from "../common/loader";

function ChangePasswordForm({ handleChangePWClick }) {
  const [loader, setLoader] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const { verifyFPToken } = useParams();
  const [verificationStatus, setVerificationStatus] = useState('pending');

  useEffect(() => {
    // Add token validation logic here if needed
    setVerificationStatus('verified'); // Assuming the token is valid for demonstration
  }, [verifyFPToken]);

  const validateForm = () => {
    const errors = {};

    if (!newPassword) {
      errors.newPassword = "Password is required";
    } else if (newPassword.length < 6) {
      errors.newPassword = "Password must be at least 6 characters";
    }

    if (!confirmNewPassword) {
      errors.confirmNewPassword = "Password is required";
    } else if (confirmNewPassword.length < 6) {
      errors.confirmNewPassword = "Password must be at least 6 characters";
    } else if (confirmNewPassword !== newPassword) {
      errors.confirmNewPassword = "Passwords do not match";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleLogout = (event) => {
    if (event) {
      event.preventDefault();
    }
    localStorage.removeItem("access_token");
    navigate("/");
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    try {
      setLoader(true);
      const token = localStorage.getItem('access_token');

      const response = await axios.post(
        'https://app.sapius.ai/api/profile/change_password',
        {
          password: newPassword,
          confirm_password: confirmNewPassword
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setLoader(false);
      setSuccess("Password reset successfully.");
      setError('');

      setTimeout(handleLogout, 5000); // Call handleLogout without event
    } catch (error) {
      setLoader(false);
      setError(error.response?.data?.message || "An error occurred while changing the password. Please try again.");
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="mainContainer py-6 pt-2 relative z-0">
        <div className="container mx-auto max-w-4xl">
          <div className="loginContainer">
            {success ? (
              <div className="mx-auto max-w-md text-center mt-16 rounded-md">
                <h1 className="text-center mb-4 text-green">
                  Your Password Updated successfully, Please login again
                </h1>
                <button
                  type="submit"
                  onClick={handleLogout}
                  className="text-md mb-5 font-semibold bg-primary text-white rounded-md px-4 py-2 block hover:bg-primary-700 mx-auto"
                >
                  Login
                </button>
              </div>
            ) : (
              <form onSubmit={handleChangePassword}>
                <div className="border-gray-200 py-5 sm:p-0 text-left">
                  <dl className="sm:divide-y sm:divide-gray-100">
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">New Password</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div className="flex gap-2">
                          <input
                            type="password"
                            name="lastname"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="border outline-none rounded-sm h-9 px-3 w-full"
                          />
                        </div>
                        {validationErrors.newPassword && <p className="error mt-2">{validationErrors.newPassword}</p>}
                      </dd>
                    </div>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Confirm Password</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div className="flex gap-2">
                          <input
                            type="password"
                            name="confirmNewPassword"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            className="border outline-none rounded-sm h-9 px-3 w-full"
                          />
                        </div>
                        {validationErrors.confirmNewPassword && <p className="error mt-2">{validationErrors.confirmNewPassword}</p>}
                      </dd>
                    </div>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500"></dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <button
                          type="submit"
                          className="text-md mb-5 font-semibold bg-primary text-white rounded-md px-4 py-2 block hover:bg-primary-700"
                        >
                          Submit
                        </button>
                      </dd>
                    </div>
                    {error && <p className="error mt-3">{error}</p>}
                  </dl>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePasswordForm;
