import './App.css';
import AppRoutes from "./Routes";
import { UserProvider } from './context/UserContext';


function App() {
  return (
    <div className="App font-metropolis h-screen">
      <UserProvider>
        <AppRoutes />
      </UserProvider>
    </div>
  );
}

export default App;
