import React, { useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import ForgotPasswordForm from "../components/auth/ForgotPasswordForm";

function ForgotPassword() {


  return (
    <>

      <div className="h-full">

        <Header />

        <ForgotPasswordForm />

        <Footer />


      </div>
    </>
  );
}

export default ForgotPassword