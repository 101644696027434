import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Question from "../components/chatbot/Question";
import HealthDetails from "../components/chatbot/HealthDetails";



function Chatbot() {

  const [questionData, setQuestionData] = useState(null);
  const [questionResponseData, setQuestionResponseData] = useState(null);

  const handleQuestionSubmit = (questionData, questionResponseData) => {
    setQuestionData(questionData);
    setQuestionResponseData(questionResponseData);
  };

  const [expandedTextareaId, setExpandedTextareaId] = useState(null);






  return (
    <>

      <div className="h-full">
        <Header />


        {questionData && questionResponseData ? (
          <HealthDetails
            questionData={questionData}
            questionResponseData={questionResponseData}
            expandedTextareaId={expandedTextareaId}
            setExpandedTextareaId={setExpandedTextareaId}
          />

        ) : (
          <Question onSubmit={handleQuestionSubmit} />
        )}



        <div>

        </div>


        <Footer />
      </div >
    </>
  );
}

export default Chatbot