import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import editIcon from "./../assets/images/editIcon.svg";
import copyIcon from "./../assets/images/copyIcon.svg";
import closeIcon from "./../assets/images/closeIcon.svg";
import { UserContext } from '../context/UserContext';
import ChangePasswordForm from "../components/auth/ChangePasswordForm";

function MyProfile() {
  const navigate = useNavigate();
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [formData, setFormData] = useState({
    firstname: userDetails?.firstname || '',
    lastname: userDetails?.lastname || '',
    email: userDetails?.email || '',
    mobile: userDetails?.mobile || ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [copied, setCopied] = useState('');
  const [profileImage, setProfileImage] = useState();
  const [validationErrors, setValidationErrors] = useState({});

  const user_nid = userDetails?.nid;



  const validateForm = () => {
    const errors = {};

    if (!formData.firstname) {
      errors.firstname = "Firstname is required";
    }

    if (!formData.lastname) {
      errors.lastname = "Lastname is required";
    }

    if (!formData.mobile) {
      errors.mobile = "Mobile number is required";
    } else if (!/^\d+$/.test(formData.mobile)) {
      errors.mobile = "Mobile number should contain only digits";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };


  console.log('image API ------------>', `https://app.sapius.ai/api/profile/view/${user_nid}`);
  useEffect(() => {
    
    setProfileImage(`https://app.sapius.ai/api/profile/view/${user_nid}`);
  }, [user_nid]);

  const referral_code = localStorage.getItem('referral_code');

console.log('profileImage------->', profileImage);

  const copyRefLink = () => {
    const refLink = `https://sapius.ai/register/${referral_code}`;
    navigator.clipboard.writeText(refLink)
      .then(() => {
        setCopied('Referral link copied to clipboard!');
        setTimeout(() => setCopied(''), 3000); // Clears the copied message after 3 seconds
      })
      .catch((err) => {
        console.error('Failed to copy referral link:', err);
        setCopied('Failed to copy referral link.');
        setTimeout(() => setCopied(''), 3000); // Clears the copied message after 3 seconds
      });
  };



  useEffect(() => {
    setFormData({
      firstname: userDetails?.firstname || '',
      lastname: userDetails?.lastname || '',
      email: userDetails?.email || '',
      mobile: userDetails?.mobile || ''
    });
  }, [userDetails]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleChangePWClick = () => {
    setIsChangePassword(!isChangePassword);
  };

  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFile(file);
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  console.log('file----------->', file);
  console.log('imagePreviewUrl----------->', imagePreviewUrl);
  

  let imagePreview = null;
  if (imagePreviewUrl) {
    imagePreview = <img src={imagePreviewUrl} className="rounded-full w-36 h-36 object-cover" alt="Profile Photo" />;
  } else {
    imagePreview = <div className="previewText"><img src={profileImage} className="rounded-full w-36 h-36 object-cover" alt="Profile Photo" /></div>;
  }


  const uploadProfileImage = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = localStorage.getItem('access_token');
      await axios.post('https://app.sapius.ai/api/profile/profile_image', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error('Error uploading profile image:', error);
      setSuccessMessage('Error updating profile image. Please try again.');
      setTimeout(() => setSuccessMessage(''), 5000);
    }
  };

  const handleEditFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const token = localStorage.getItem('access_token');
// return console.log('formData----------->', formData);

      // Update Profile Details
      const response = await axios.post('https://app.sapius.ai/api/profile/update', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success) {
        const updatedDetails = JSON.parse(response?.config?.data);
        const userData = {...userDetails};
        Object.assign(userData, updatedDetails);
        setUserDetails(userData);


        console.log("Profile updated");

        // Update Profile Image
        await uploadProfileImage();

        // Fetch updated user details
        // const updatedUserResponse = await axios.get('https://app.sapius.ai/api/profile/view', {
        //   headers: {
        //     'Authorization': `Bearer ${token}`
        //   }
        // });
        // console.log('updatedUserResponse------>', updatedUserResponse);

        // if (updatedUserResponse.data.success) {
        //   setUserDetails(updatedUserResponse.data.userDetails);
        // }
        setIsEditing(false);
        setSuccessMessage('Profile updated successfully!');
        setTimeout(() => setSuccessMessage(''), 5000);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setSuccessMessage('Error updating profile. Please try again.');
      setTimeout(() => setSuccessMessage(''), 5000);
    }
  };



  return (
    <>


      <div className="h-full">
        <Header />

        <div className="h-full">
          <div className="mainContainer py-6 pt-24 relative z-0">
            <div className="container mx-auto max-w-4xl">
              <div className="myProfile">
                <h1 className="text-center font-semibold text-3xl mb-8 text-primary mt-10">My Profile</h1>

                {successMessage && (
                  <div className="text-green font-semibold px-4 py-3 relative" role="alert">
                    <span className="block sm:inline">{successMessage}</span>
                  </div>
                )}


                <div className="flex gap-6 mt-5">
                  <div className="bg-white w-1/3 rounded-md px-3 py-10 items-center text-center">


                    <div className="profileImage relative items-center w-36 h-36 mx-auto group mb-6">
                      {imagePreview}

                      {isEditing && (
                        <label htmlFor="uploadFile1"
                          className="bg-gray-900 text-gray-700 font-semibold text-base rounded-full w-36 h-36 hidden group-hover:flex flex-col items-center justify-center cursor-pointer border-2 border-white border-dashed mx-auto absolute top-0 left-0 bg-opacity-50">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="size-10 fill-white" viewBox="0 0 24 24" strokeWidth="1" stroke="#666">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                          </svg>
                          <input type="file" id='uploadFile1' onChange={handleImageChange} className="hidden" />
                        </label>
                      )}
                    </div>


                    <p className="mt-6">{userDetails?.firstname}</p>
                    <p>{userDetails?.email}</p>

                    <p className="mt-10 text-primary hover:underline">
                      {/* <Link to="#" onClick={handleChangePWClick}>Change Password</Link> */}
                      <Link to="/change-password" >Change Password</Link>
                    </p>





                  </div>



                  {isChangePassword ? (

                    <div className="bg-white w-2/3 rounded-md">
                      <div className="border-gray-200 py-4 px-6 text-right flex w-full justify-between">
                        <h2 className="font-metropolis-700 text-primary">Change Password</h2>
                        <button onClick={handleChangePWClick} className="m-0">
                          <img src={closeIcon} className="opacity-30 hover:opacity-70 w-6 h-6" />
                        </button>
                      </div>
                      <ChangePasswordForm handleChangePWClick={handleChangePWClick} />
                    </div>

                  ) : (


                    <div className="bg-white w-2/3 rounded-md">
                      <div className="border-gray-200 py-4 px-6 text-right flex w-full justify-between">
                        <h2 className="font-metropolis-700 text-primary">{isEditing ? "Edit Profile" : "Profile Information"}</h2>
                        <button onClick={handleEditClick} className="m-0">
                          {isEditing ?
                            <img src={closeIcon} className="opacity-30 hover:opacity-70 w-6 h-6" /> :
                            <img src={editIcon} className="opacity-50 hover:opacity-100" />
                          }
                        </button>
                      </div>
                      <form onSubmit={handleEditFormSubmit}>
                        <div className="border-gray-200 px-4 py-5 sm:p-0 text-left">
                          <dl className="sm:divide-y sm:divide-gray-100">

                            {isEditing ? (

                              <>
                                <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                                  <dt className="text-sm font-medium text-gray-500">First Name</dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                                    <div className="flex gap-2">

                                      <input
                                        type="text"
                                        name="firstname"
                                        value={formData.firstname}
                                        onChange={handleInputChange}
                                        className="border outline-none rounded-sm h-9 px-3 w-full"
                                      />
                                    </div>
                                    {validationErrors.firstname && <p className="error mt-2 text-red-500">{validationErrors.firstname}</p>}

                                  </dd>
                                </div>

                                <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                                  <dt className="text-sm font-medium text-gray-500">Last Name</dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                                    <div className="flex gap-2">

                                      <input
                                        type="text"
                                        name="lastname"
                                        value={formData.lastname}
                                        onChange={handleInputChange}
                                        className="border outline-none rounded-sm h-9 px-3 w-full"
                                      />
                                    </div>
                                    {validationErrors.lastname && <p className="error mt-2 text-red-500">{validationErrors.lastname}</p>}

                                  </dd>
                                </div>
                              </>


                            ) : (
                              <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                                <dt className="text-sm font-medium text-gray-500">Full Name</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  {userDetails?.firstname} {userDetails?.lastname}
                                </dd>
                              </div>


                            )}

                            <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">Email address</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {isEditing ? (
                                  <>
                                    <input
                                      type="email"
                                      name="email"
                                      disabled
                                      value={formData.email}
                                      onChange={handleInputChange}
                                      className="border outline-none rounded-sm h-9 px-3 w-full"
                                    />
                                    <span className="text-center italic text-gray-400"> The email address cannot be changed. If you still wish to change it, please contact the support team</span>
                                  </>
                                ) : (
                                  userDetails?.email
                                )}
                              </dd>
                            </div>
                            <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                              <dt className="text-sm font-medium text-gray-500">Phone number</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleInputChange}
                                    className="border outline-none rounded-sm h-9 px-3 w-full"
                                  />
                                ) : (
                                  userDetails?.mobile
                                )}

                                {validationErrors.mobile && <p className="error mt-2 text-red-500">{validationErrors.mobile}</p>}

                              </dd>
                            </div>
                            <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">Account Status</dt>
                              <dd className={`mt-1 text-sm sm:mt-0 sm:col-span-2 ${userDetails?.status === 1 ? 'text-green' : 'text-red'}`}>
                                {userDetails?.status === 1 ? 'Active' : 'Inactive'}
                              </dd>
                            </div>
                            <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">Plan</dt>
                              <dd className="mt-1 text-sm text-green sm:mt-0 sm:col-span-2">
                                Wellness
                                <span className="text-primary hover:underline float-end inline-block"><Link to="">Change Plan</Link></span>
                              </dd>
                            </div>
                            <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                              <dt className="text-sm font-medium text-gray-500">Referral Link</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <div className="flex items-center space-x-2">


                                  {referral_code ? (
                                    <div className="flex items-center space-x-2">
                                      <span id="refCode" className="border-dashed border-0 border-gray-200 text-gray-500 px-4 py-2 rounded-l">
                                        https://sapius.ai/register/{referral_code}
                                      </span>
                                      <span id="copyBtn" onClick={copyRefLink} className="px-4 py-2 cursor-pointer relative">
                                        {copied && (
                                          <div className="text-primary px-4 py-3 absolute -right-2 bottom-6" role="alert">
                                            <span className="block sm:inline">Copied</span>
                                          </div>
                                        )}
                                        <img src={copyIcon} className="w-6 opacity-30 hover:opacity-100" alt="Copy" />
                                      </span>
                                    </div>
                                  ) : (
                                    <span>Referral link is not available</span>
                                  )}

                                </div>
                              </dd>
                            </div>
                            {isEditing && (
                              <div className="text-center p-4 gap-2 flex justify-center">
                                <button className="bg-blue-500 text-white py-2 px-4 rounded bg-gray-400" onClick={handleEditClick}>Cancel</button>
                                <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded bg-primary">Save</button>
                              </div>
                            )}
                          </dl>
                        </div>
                      </form>
                    </div>

                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default MyProfile;
