import React, { } from "react";

function Loader() {


    return (
        <>
            <div className="fixed w-full h-full flex justify-center items-center bg-white bg-opacity-50 border z-50 left-0 top-0">
                <div className="w-12 h-12 rounded-full animate-spin border-4 border-solid border-primary border-t-transparent"></div>
            </div>
            {/* 
            <div className="w-full h-full flex justify-center items-center z-50 left-0 top-0 mb-5">
                <div className="w-12 h-12 rounded-full animate-spin border-4 border-solid border-primary border-t-transparent"></div>
            </div> */}

        </>
    );
}

export default Loader