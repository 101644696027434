import React, { useState, useEffect, useContext } from "react";
import logo from "./../../assets/images/logo.png";
import userIcon from "./../../assets/images/userIcon.svg";
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import key from "./../../assets/images/key.svg";
import logoutIcon from "./../../assets/images/logout.svg";
import user from "./../../assets/images/user.svg";
import profilePlaceholder from "./../../assets/images/profile_placeholder.jpg";


function Header() {
  const navigate = useNavigate();
  const [logout, setLogout] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { userDetails } = useContext(UserContext);



  useEffect(() => {
    const loggedInStatus = localStorage.getItem('access_token');
    const userDetails = localStorage.getItem('userDetails');
    setIsLoggedIn(!!loggedInStatus);
    // console.log("HeaderuserDetails", userDetails)

  }, []);

  const user_nid = userDetails?.nid;
  const [profileImage, setProfileImage] = useState();

  useEffect(() => {
    setProfileImage(`https://app.sapius.ai/api/profile/view/${user_nid}`);
  }, [user_nid]);



  // useEffect(() => {
  //   if (!localStorage.getItem("access_token")) navigate("/");
  // }, [logout, navigate]);

  const handleLogout = (event) => {
    event.preventDefault();
    localStorage.removeItem("access_token");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("referral_code");
    setLogout(true);
    navigate("/");

  }


  const [toggleMenu, setToggleMenu] = useState(false);

  const handleToggle = () => {
    setToggleMenu(!toggleMenu)
  }

  return (
    <>


      <header className="bg-white py-2 lg:py-4 fixed w-full z-20">
        <div className="container-fluid px-4 mx-auto lg:flex lg:items-center justify-between">
          <div className="flex justify-between items-center relative z-50">
            <Link to="#" className="font-bold text-xl text-indigo-600">
              <img src={logo} className="mr-2 h-10" alt="Logo" />
            </Link>

            <div className="lg:hidden flex justify-center gap-4 items-center">

              {isLoggedIn ? (
                <>


                  {/* <img src={userIcon} className="mr-2 h-8" alt="User Icon" /> */}


                  {!userDetails?.profile_image_url ? (
                    <img src={profileImage} className="rounded-full mr-2 h-8" />
                  ) : (
                    <img src={userDetails?.profile_image_url} className="rounded-full mr-2 h-8" />
                  )}



                  <Link onClick={handleLogout} to="#" className="p-2 text-primary font-bold hover:text-primary transition-colors duration-300 flex items-center gap-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#999" className="size-8">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9" />
                    </svg>
                  </Link>

                </>
              ) : (

                <Link to="/" className="p-2 px-5  font-semibold text-gray-700 bg-primary text-center border border-solid border-primary rounded hover:bg-primary-700 hover:text-gray-700 transition-colors duration-300 lg:ml-1">
                  Login
                </Link>

              )}


              <button onClick={handleToggle} className="border border-solid border-gray-600 px-3 py-1 rounded text-gray-700 opacity-50 hover:opacity-75" id="navbar-toggle">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-8">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </button>
            </div>
          </div>


          {toggleMenu ? <div className="fixed w-full h-full flex justify-center items-center bg-white bg-opacity-80 z-10 left-0 top-0"></div> : ""}

          <nav className={toggleMenu ? "flex-col lg:flex-row lg:ml-auto mt-3 lg:mt-0 items-center z-50 relative" : "hidden lg:flex"}>
            <ul>
              <li className="lg:inline-block cursor-pointer hover:text-gray-500 border-b lg:border-none py-4 px-3 text-right">
                <Link to="/" className="p-2  text-gray-700 font-semibold hover:text-primary transition-colors duration-300">Home</Link></li>
              <li className="lg:inline-block cursor-pointer hover:text-gray-500 border-b lg:border-none py-4 px-3 text-right">
                <Link to="https://sapius.ai/about" className="p-2  text-gray-700  hover:text-primary transition-colors duration-300">About</Link></li>
              <li className="lg:inline-block cursor-pointer hover:text-gray-500 border-b lg:border-none py-4 px-3 text-right">
                <Link to="https://sapius.ai/Consumer" className="p-2  text-gray-700  hover:text-primary transition-colors duration-300">Consumers</Link></li>
              <li className="lg:inline-block cursor-pointer hover:text-gray-500 border-b lg:border-none py-4 px-3 text-right">
                <Link to="https://sapius.ai/Doctor" className="p-2  text-gray-700  hover:text-primary transition-colors duration-300">Doctors</Link></li>

              <li className="lg:inline-block cursor-pointer hover:text-gray-500 border-b lg:border-none py-4 px-3 text-right">
                <Link to="https://sapius.ai/Enterprise" className="p-2  text-gray-700  hover:text-primary transition-colors duration-300">Enterprises</Link></li>
              <li className="lg:inline-block cursor-pointer hover:text-gray-500 border-b lg:border-none py-4 px-3 text-right">
                <Link to="#" className="p-2  text-gray-700  hover:text-primary transition-colors duration-300">Pricing</Link></li>

              {isLoggedIn ? (
                <>
                  <li className="hidden lg:inline-flex justify-center items-center  hover:text-gray-500 border-b lg:border-none py-0 px-3">


                    {/* <Link onClick={handleLogout} to="#" className="cursor-pointer hover:opacity-50">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#999" className="size-8">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9" />
                      </svg>
                    </Link> */}


                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <MenuButton className="inline-flex justify-center gap-x-1.5  bg-white px-2 py-2 pr-0 text-sm font-semibold text-gray-900">

                          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                            <path fillRule="evenodd" d="M10.5 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" clipRule="evenodd" />
                          </svg> */}

                          <span className="p-2 pr-0 text-primary font-bold hover:text-primary  transition-colors duration-300 flex items-center gap-3">  {userDetails?.firstname}
                            {/* <img src={userIcon} className="mr-2 h-8" alt="User Icon" /> */}
                            {!userDetails?.profile_image_url ? (
                              <img src={profileImage} className="rounded-full  h-8 hover:outline-2" />
                            ) : (
                              <img src={userDetails?.profile_image_url} className="rounded-full h-8 hover:outline-2" />
                            )}

                          </span>

                        </MenuButton>
                      </div>

                      <MenuItems
                        transition
                        className="absolute right-0 z-10 w-52 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-300 ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <div className="py-0">

                          <MenuItem>
                            <Link
                              to="/myprofile"
                              className="flex items-center  px-4 py-3 text-sm text-gray-700 data-[focus]:bg-gray-50 data-[focus]:text-gray-900 border-b border-gray-100"
                            >
                              <img src={user} className="mr-2 h-4" />  My Profile
                            </Link>
                          </MenuItem>
                          <MenuItem>
                            <Link
                              to="/change-password"
                              className="flex items-center px-4 py-3 text-sm text-gray-700 data-[focus]:bg-gray-50 data-[focus]:text-gray-900 border-b border-gray-100"
                            >
                              <img src={key} className="mr-2 h-4" /> Change Password
                            </Link>

                          </MenuItem>
                          <form action="#" method="POST">
                            <MenuItem>
                              <button
                                onClick={handleLogout}
                                type="submit"
                                className="flex items-center  w-full px-4 py-3 text-left text-sm text-gray-700 data-[focus]:bg-gray-50 data-[focus]:text-gray-900"
                              >
                                <img src={logoutIcon} className="mr-2 h-4" /> Sign out
                              </button>
                            </MenuItem>
                          </form>
                        </div>
                      </MenuItems>
                    </Menu>

                  </li>
                </>
              ) : (
                <li className="hidden lg:inline-block cursor-pointer hover:text-gray-500 border-b lg:border-none py-2 px-3">
                  <Link to="/" className="p-3 px-5  font-semibold text-gray-700 bg-primary text-center border border-solid border-primary rounded hover:bg-primary-700 hover:text-gray-700 transition-colors duration-300 mt-1 lg:mt-0 lg:ml-1">
                    Login
                  </Link>
                </li>
              )}

            </ul>
          </nav>
        </div>
      </header >
    </>
  );
}

export default Header;
