import React, { } from "react";
import { Link } from 'react-router-dom';



function Footer() {



  return (
    <>

      <footer className="hidden md:flex flex-col md:flex-row justify-end fixed bottom-0 right-4	mt-3 md:mt-0 text-sm">
        <Link to="#" className="p-2 lg:px-4 md:mx-2 text-gray  hover:text-primary transition-colors duration-300"> Privacy</Link>
        <Link to="#" className="p-2 lg:px-4 md:mx-2 text-gray  hover:text-primary transition-colors duration-300"> Terms</Link>
        <Link to="#" className="p-2 lg:px-4 md:mx-2 text-gray  hover:text-primary transition-colors duration-300"> News</Link>
        <Link to="#" className="p-2 lg:px-4 md:mx-2 text-gray  hover:text-primary transition-colors duration-300"> Reviews</Link>
      </footer>


    </>
  );
}

export default Footer