import React, { useState, useEffect, Component } from "react";
import { Link, useNavigate } from 'react-router-dom';



function Authentication(props) {

  const { Component } = props;
  const navigate = useNavigate();

  useEffect(() => {
    let isAuth = localStorage.getItem("access_token")
    if (!isAuth) {
      navigate("/")
    }
  }, []);


  return (
    <>

      <Component />


    </>
  );
}

export default Authentication