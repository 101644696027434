import React, { useEffect, useState } from "react";
import steps from "../../assets/images/steps.png";
import axios from 'axios';
import Loader from "../common/loader";
import { Link, useNavigate } from 'react-router-dom';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

import chatUser from '../../assets/images/chat_user.png'
import sapiusIcon from '../../assets/images/chat_sapius.png'




function HealthDetails({ questionData, questionResponseData, expandedTextareaId, setExpandedTextareaId }) {
  const [responseList, setResponseList] = useState({});
  const [textareaValues, setTextareaValues] = useState({});
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [additionalQuestion, setAdditionalQuestion] = useState("");
  const [getSummaryData, setGetSummaryData] = useState([]);
  const [htmlContent, setHtmlContent] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [getChatData, setGetChatData] = useState('');
  const [chatQuestion, setChatQuestion] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [chatResult, setChatResult] = useState('');
  const [chatQuestionError, setChatQuestionError] = useState('');
  const [isQuestionSubmitted, setIsQuestionSubmitted] = useState(false);
  const [lastSubmittedQuestion, setLastSubmittedQuestion] = useState('');
  const [submittedQuestions, setSubmittedQuestions] = useState([]);
  const [additionalQuestionError, setAdditionalQuestionError] = useState('');


  const handleButtonClick = (text) => {
    setChatQuestion(text);
    // Use setTimeout to ensure the state is updated before calling handleFCQuery
    setTimeout(() => {
      handleFCQuery(text);
    }, 0);
  };


  if (!questionResponseData) {
    return null;
  }

  const token = localStorage.getItem('access_token');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setResponseList({
      ...responseList,
      [name]: type === 'radio' ? value : checked
    });

    const [id, qIndex] = name.split('-').slice(2);
    setSelectedOptions({
      ...selectedOptions,
      [name]: value
    });

    console.log("selectedQuestion ---->:", responseList);
  };

  const handleTextareaChange = (e, id, qIndex) => {
    const { value } = e.target;
    setTextareaValues({
      ...textareaValues,
      [`textarea-${id}-${qIndex}`]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate additionalQuestion
    if (!additionalQuestion.trim()) {
      setAdditionalQuestionError('Please enter additional information or a question.');
      return;
    }

    // Clear previous error if any
    setAdditionalQuestionError('');


    const responseArray = questionResponseData.response.possibleCauses.flatMap((possibleCauses, id) =>
      possibleCauses.followup_questions.map((question, qIndex) => {
        const selectedOption = responseList[`default-radio-${id}-${qIndex}`] ?? "";
        return {
          answer: selectedOption === 'Other' ? textareaValues[`textarea-${id}-${qIndex}`] : selectedOption,
          question: question
        };
      })
    );

    const questionId = questionResponseData.question_id;

    const payload = {
      additional_question: additionalQuestion,
      question_id: questionId,
      response_lst: responseArray
    };

    setQuestionId(questionId);

    console.log("questionId>>>>:", questionId);
    console.log("Payload:", payload);
    console.log("response_lst --->>:", payload.response_lst);

    try {
      nextStep();
      setLoader(true);
      const response = await axios.post('https://app.sapius.ai/api/chat/followup_query', payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      const getSummaryData = response.data.response;
      setGetSummaryData(getSummaryData);

      console.log("getSummaryData", getSummaryData);
      console.log("Token", token);

      let htmlContent = marked(typeof getSummaryData === 'string' ? getSummaryData : JSON.stringify(getSummaryData));
      const sanitizedHtml = DOMPurify.sanitize(htmlContent);
      setHtmlContent(sanitizedHtml);
      console.log("sanitizedHtml ---->", sanitizedHtml);

      setLoader(false);

    } catch (error) {
      setError('There was an error with the request');
      console.error('There was an error!', error);
      setLoader(false);
    }
  };

  const steps = [
    'Symptoms',
    'Questions',
    // 'Summary',
    'Sapius Chat'
  ];

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const previousStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const goToStep = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const print = () => {
    window.print();
  };

  const handleGoBackQuestion = () => {
    navigate(-1);
  };




  const questionPayload = {
    question: chatQuestion,
    question_id: questionId
  };

  const handleFCQuery = async (buttonText = null) => {
    const questionToAsk = buttonText || chatQuestion;

    if (!questionToAsk.trim()) {
      setChatQuestionError('Please enter a question or additional information.');
      return;
    }

    // Clear previous error if any
    setChatQuestionError('');

    try {
      setLoader(true);

      const questionPayload = {
        question: questionToAsk,
        question_id: questionId
      };

      const response = await axios.post('https://app.sapius.ai/api/chat/followup_conversation_query', questionPayload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      const getChatData = response.data.response;
      setGetChatData(getChatData);

      console.log("getSummaryData:>>>>", getChatData);

      let chatResult = marked(typeof getChatData === 'string' ? getChatData : JSON.stringify(getChatData));
      const sanitizedHtml1 = DOMPurify.sanitize(chatResult);
      setChatResult(sanitizedHtml1);
      console.log("sanitizedHtml ---->", sanitizedHtml1);

      setSubmittedQuestions(prevQuestions => [
        ...prevQuestions,
        { question: questionToAsk, result: sanitizedHtml1 }
      ]);

      setLoader(false);
      setIsQuestionSubmitted(true);
      setLastSubmittedQuestion(questionToAsk);
      setChatQuestion('');

    } catch (error) {
      setLoader(false);
      console.error('There was an error!', error);
    }
  };

  return (
    <>
      <div className="h-full">
        <div className="mainContainer py-6 pt-20 lg:pt-24 relative">
          <div className="container mx-auto p-5">
            <div className="stepsContainer max-w-4xl mx-auto items-center mb-20">

              <div className="stepper">
                <div className="stepper-buttons">
                  {steps.map((step, index) => (
                    <button
                      key={index}
                      onClick={() => goToStep(index)}
                      className={currentStep === index ? 'current-step' : ''}
                      disabled={currentStep === index} >
                      <div className="count"> <span> {index + 1}</span></div>
                      {step}
                    </button>
                  ))}
                </div>
                <div className="stepper-line">
                  <div
                    className="stepper-progress"
                    style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
                  />
                </div>
              </div>

            </div>

            <div className="stepper-content mt-10">
              {currentStep === 0 && (
                <div>
                  <div className="flex flex-col max-w-3xl mx-auto text-left">
                    <h2 className="text-md text-gray-400 mb-4 text-left italic">
                      Considering your initial symptoms, these are the multiple potential diagnoses.
                    </h2>

                    {questionResponseData.response.possibleCauses.map((possibleCauses, id) => (
                      <div key={id} className="flex flex-row min-h-12 items-center bg-primary-200 w-full border-l-4 border-primary py-3 px-5 rounded-md mb-5">
                        <div className="flex justify-center items-center gap-5">
                          <div className="flex flex-col items-left">
                            <h2 className="text-md font-bold text-gray-900 dark:text-gray-300">
                              {possibleCauses.name}
                            </h2>
                            <p>{possibleCauses.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {currentStep === 1 && (
                <div>
                  <div className="flex flex-col max-w-3xl mx-auto text-left">

                    <h2 className="text-md text-gray-400 mb-4 text-left italic">
                      Please answer the follow-up questions related to your symptoms below. Please note, you donot have to answer all the questions,
                      only those questions which are releveant to your symptoms.
                    </h2>
                  </div>
                  {questionResponseData.response.possibleCauses.map((possibleCauses, id) => (
                    <div key={id} className="flex flex-col max-w-3xl mx-auto text-left">
                      <h2 className="text-lg font-bold mb-4">
                        {possibleCauses.name}
                      </h2>

                      {possibleCauses.followup_questions.map((question, qIndex) => (
                        <React.Fragment key={qIndex}>
                          <div className="flex flex-row min-h-12 sm:items-center bg-primary-200 w-full border-l-4 border-primary py-4 sm:py-1 px-5 rounded-md mb-5">
                            <div className="flex flex-col sm:flex-row justify-between sm:items-center gap-5 w-full">
                              <div className="flex items-center">
                                <label className="sm:ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                  {question}
                                </label>
                              </div>

                              <div className="flex items-center gap-5 sm:justify-end">
                                <div className="flex items-center">
                                  <input
                                    value="Yes"
                                    onChange={handleChange}
                                    id={`Yes-${id}-${qIndex}`}
                                    type="radio"
                                    name={`default-radio-${id}-${qIndex}`}
                                    className="w-6 h-6 text-blue-600 bg-gray-100 border-primary-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  <label htmlFor={`Yes-${id}-${qIndex}`} className="ms-2 text-sm font-semibold text-gray-900 dark:text-gray-300">
                                    Yes
                                  </label>
                                </div>

                                <div className="flex items-center">
                                  <input
                                    value="No"
                                    onChange={handleChange}
                                    id={`No-${id}-${qIndex}`}
                                    type="radio"
                                    name={`default-radio-${id}-${qIndex}`}
                                    className="w-6 h-6 text-blue-600 bg-gray-100 border-primary-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  <label htmlFor={`No-${id}-${qIndex}`} className="ms-2 text-sm font-semibold text-gray-900 dark:text-gray-300">
                                    No
                                  </label>
                                </div>

                                <div className="flex items-center">
                                  <input
                                    value="Other"
                                    onChange={handleChange}
                                    id={`Other-${id}-${qIndex}`}
                                    type="radio"
                                    name={`default-radio-${id}-${qIndex}`}
                                    className="w-6 h-6 text-blue-600 bg-gray-100 border-primary-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  <label htmlFor={`Other-${id}-${qIndex}`} className="ms-2 text-sm font-semibold text-gray-900 dark:text-gray-300">
                                    Other
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          {selectedOptions[`default-radio-${id}-${qIndex}`] === 'Other' && (
                            <div className="w-full mb-5">
                              <textarea
                                className="rounded-md w-full min-h-20 border border-primary p-3 outline-none"
                                onChange={(e) => handleTextareaChange(e, id, qIndex)}
                                value={textareaValues[`textarea-${id}-${qIndex}`] || ''}
                              ></textarea>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  ))}

                  <div className="flex flex-col max-w-3xl mx-auto text-left mt-5">
                    <h2 className="text-lg font-bold mb-4">
                      You can enter more information or ask me anything.
                    </h2>

                    <div className="w-full mb-5">
                      <textarea
                        required
                        value={additionalQuestion}
                        placeholder="You can enter more information"
                        onChange={(e) => {
                          setAdditionalQuestion(e.target.value);
                          setAdditionalQuestionError(''); // Clear error when user types
                        }}
                        className={`rounded-md w-full min-h-20 border ${additionalQuestionError ? 'border-red-500' : 'border-primary'} p-3 outline-none`}
                      ></textarea>
                      {additionalQuestionError && <p className="text-red-500 text-sm mt-1">{additionalQuestionError}</p>}
                    </div>


                    {/* <div className="w-full mb-5">
                      <textarea
                        required
                        value={additionalQuestion}
                        onChange={(e) => setAdditionalQuestion(e.target.value)}
                        className="rounded-md w-full min-h-20 border border-primary p-3 outline-none">
                      </textarea>
                    </div> */}
                  </div>
                </div>
              )}

              {currentStep === 2 && (
                <div>



                  <div className="flex flex-col max-w-3xl mx-auto text-left mt-5">

                    {loader && (
                      <div className="mb-5">
                        <Loader />
                      </div>
                    )}


                    <div className="w-full mb-5 text-center bg-white">
                      <div className="relative shadow-md py-5">
                        <table className="w-full overflow-auto text-left">
                          <thead className="text-gray-700 border-b-4 border-primary-200">

                            <tr className="bg-white">
                              <th scope="row" className="px-6 pt-0 text-lg whitespace-nowrap w-1/2">
                                <h2>Patient Details</h2>
                              </th>
                            </tr>

                            <tr>
                              <th scope="col" className="px-6 py-3 font-normal">
                                Age
                              </th>
                              <th scope="col" className="px-6 py-3">
                                {questionData.user_age}
                              </th>
                            </tr>
                            <tr>
                              <th scope="col" className="px-6 py-3 font-normal">
                                Gender
                              </th>
                              <th scope="col" className="px-6 py-3">
                                {questionData.user_gender}
                              </th>
                            </tr>
                            <tr>
                              <th scope="col" className="px-6 py-3 font-normal">
                                Symptoms
                              </th>
                              <th scope="col" className="px-6 py-3">
                                {questionData.question}
                              </th>
                            </tr>
                            <tr>
                              <th scope="col" className="px-6 py-3 font-normal">
                                How long you feel the issues?
                              </th>
                              <th scope="col" className="px-6 py-3">
                                {questionData.problem_duration}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="bg-white">
                              <th scope="row" className="px-6 pt-5 text-lg whitespace-nowrap w-1/2">
                                <h2>Symptoms</h2>
                              </th>
                            </tr>


                            {questionResponseData.response.possibleCauses.map((possibleCauses, id) => (
                              <React.Fragment key={id}>
                                <tr className="bg-white">
                                  <th colSpan="2" scope="row" className="px-6 pt-4 whitespace-nowrap">
                                    <h2 className="text-primary font-bold">{possibleCauses.name}</h2>
                                  </th>
                                </tr>
                                {possibleCauses.followup_questions.map((question, qIndex) => (
                                  <tr key={`${id}-${qIndex}`} className="bg-white">
                                    <td className="px-6 py-2">{question}</td>
                                    <td className="px-6 py-2 font-bold">
                                      {selectedOptions[`default-radio-${id}-${qIndex}`] === 'Other'
                                        ? textareaValues[`textarea-${id}-${qIndex}`]
                                        : responseList[`default-radio-${id}-${qIndex}`] || '-'}
                                    </td>
                                  </tr>
                                ))}
                              </React.Fragment>
                            ))}

                            <tr className="bg-white">
                              <td className="px-6 py-4  font-bold">Additional Information</td>
                              <td className="px-6 py-4 font-bold">
                                {additionalQuestion}
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>


                    {/* <h2 className="text-lg font-bold mb-4 text-center">
                        Medical Report
                      </h2> */}
                    <div className="markerData shadow-md w-full mb-5 bg-white p-5" dangerouslySetInnerHTML={{ __html: htmlContent }} />




                    {submittedQuestions.map((item, index) => (
                      <div key={index} className="mb-5">
                        <div className="flex items-center space-x-2 mb-8 w-full justify-end">
                          <div className="bg-primary-300 w-auto rounded-2xl rounded-br-none min-w-72 px-4 py-3 text-sm block">
                            {item.question}
                          </div>
                          <div className="h-8 w-8 object-cover rounded-full bg-gray-100 text-center justify-center items-center flex flex-shrink-0 self-end cursor-pointer">
                            <img src={chatUser} />
                          </div>
                        </div>

                        <div className="flex items-center space-x-2 mb-8 w-full justify-end ">
                          <div className="h-8 w-8 object-cover rounded-full bg-gray-100 text-center justify-center items-center flex flex-shrink-0 self-start cursor-pointer">
                            <img src={sapiusIcon} />
                          </div>
                          <div className="bg-white  w-auto shadow-md rounded-2xl rounded-tl-none min-w-72 p-3 text-sm block">
                            <div className="markerData  w-full mt-2 bg-white p-3" dangerouslySetInnerHTML={{ __html: item.result }} />
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="chatQuestions shadow-md w-full mb-5 bg-white p-5 rounded-sm">
                      <h3>For more information after the diagnosis, please click the buttons below:</h3>
                      <div className="flex flex-col sm:flex-row mt-5 gap-3 justify-center">
                        <button
                          className="text-sm text-gray-700 font-semibold border-l-4 border-primary bg-primary-200 w-full sm:w-1/3 text-left rounded-md px-4 py-3 block hover:text-white hover:bg-primary"
                          onClick={() => handleButtonClick("Describe the treatment options for the diagnosis?")}
                        >
                          Treatment Options
                        </button>
                        <button
                          className="text-sm text-gray-700 font-semibold border-l-4 border-primary bg-primary-200 w-full sm:w-1/3 text-left rounded-md px-4 py-3 block hover:text-white hover:bg-primary"
                          onClick={() => handleButtonClick("List out the most recent FDA approved medications that can be prescribed for the diagnosis?")}
                        >
                          Approved Medications
                        </button>
                        <button
                          className="text-sm text-gray-700 font-semibold border-l-4 border-primary bg-primary-200 w-full sm:w-1/3 text-left rounded-md px-4 py-3 block hover:text-white hover:bg-primary"
                          onClick={() => handleButtonClick("Summarize the session in SOAP format in less than 300 words")}
                        >
                          Summarize the Session
                        </button>
                      </div>

                      <div className="mt-8">
                        {/* <h3 className="mb-2">You can enter more information or ask me anything:</h3> */}
                        <textarea
                          value={chatQuestion}
                          placeholder="You can enter more information or ask me anything"
                          onChange={(e) => {

                            setChatQuestion(e.target.value);
                            setChatQuestionError('')
                          }
                          }
                          className={`rounded-md w-full min-h-20 border ${chatQuestionError ? 'border-red-500' : 'border-primary'} p-3 outline-none`}

                        ></textarea>
                        {chatQuestionError && <p className="text-red-500 error text-sm mt-1">{chatQuestionError}</p>}

                      </div>






                    </div>
                  </div>

                </div>
              )}

              <div className='flex gap-4 justify-center mt-2 mb-10'>
                {currentStep === 0 && <button className="bg-primary hover:bg-primary-700 text-white px-4 py-2 rounded-md" onClick={handleGoBackQuestion}>Previous</button>}
                {currentStep > 0 && <button className="bg-primary hover:bg-primary-700 text-white px-4 py-2 rounded-md" onClick={previousStep}>Previous</button>}
                {currentStep < steps.length - 2 ? (
                  <button className="bg-primary hover:bg-primary-700 text-white px-4 py-2 rounded-md" onClick={nextStep}>Next</button>
                ) : currentStep === steps.length - 2 ? (
                  <button className="bg-primary hover:bg-primary-700 text-white px-4 py-2 rounded-md" onClick={handleSubmit}>Next</button>
                ) : (
                  <>
                    {/* <button className="bg-primary hover:bg-primary-700 text-white px-4 py-2 rounded-md" onClick={print}>Print</button> */}
                    <button className="bg-primary hover:bg-primary-700 text-white px-4 py-2 rounded-md" onClick={() => handleFCQuery()}>Chat with Sapius Bot</button>
                  </>

                )}
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default HealthDetails;
