import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from "../common/loader";
import { UserContext } from '../../context/UserContext';


function LoginForm() {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const { setUserDetails } = useContext(UserContext);


  useEffect(() => {
    if (localStorage.getItem("access_token")) navigate("/chat");
  }, []);

  const validate = () => {
    const errors = {};

    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }

    try {
      setLoader(true);
      const response = await axios.post('https://app.sapius.ai/api/auth/login', {
        email: email,
        password: password
      });

      const access_token = response.data.access_token;
      const user_details = response.data.user_details;
      const referral_code = response.data.referral_code;
      const profile_image_url = response.data.profile_image_url;
      localStorage.setItem('profile_image_url', profile_image_url);
      localStorage.setItem('access_token', access_token);
      if (referral_code !== null) {
        localStorage.setItem('referral_code', referral_code);
      }
      setUserDetails(user_details);
      setLoader(false);
      navigate("/chat");
    } catch (error) {
      setError('Invalid email or password');
      setLoader(false);
      console.error('There was an error!', error);
    }
  };

  return (
    <>

      <div className="mainContainer py-6 pt-24 relative z-0">
        <div className="loginContainer mt-16">
          <div className="mx-auto max-w-md text-left rounded-md px-6">



            {loader ? (<Loader />) : ""}


            <h1 className="text-center font-semibold text-3xl mb-8 text-primary">Login</h1>
            <form onSubmit={handleLogin}>


              <div className={`flex relative bg-white border-l-4 rounded-md mt-5  ${validationErrors.email ? 'border-red-500' : 'border-primary'}`}>
                <input
                  name="question"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setValidationErrors('')
                  }}
                  type="text"
                  className="block px-4 pt-5 pb-2 w-full text-md bg-transparent rounded-md appearance-none outline-none peer"
                  id="EmailAddress"
                  placeholder=""
                />
                <label htmlFor="EmailAddress" className="absolute text-md  text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-4 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2">Email Address</label>
              </div>
              {validationErrors.email && <p className="error mt-2">{validationErrors.email}</p>}


              <div className={`customInput flex relative bg-white border-l-4 rounded-md mt-5  ${validationErrors.password ? 'border-red-500' : 'border-primary'}`}>
                <input
                  name="question"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setValidationErrors('')
                  }}
                  type="password"
                  className="block px-4 pt-5 pb-2 w-full text-md bg-transparent rounded-md appearance-none outline-none peer"
                  id="Password"
                  placeholder=""
                />
                <label htmlFor="Password" className="absolute text-md  text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] px-3 peer-focus:px-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-4 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2">Password</label>
              </div>

              {validationErrors.password && <p className="error mt-2">{validationErrors.password}</p>}

              {error && <p className="error mt-3">{error}</p>}
              <div className="flex justify-between items-center mt-8">
                <button type="submit" className="text-lg w-full mb-5 font-semibold bg-primary text-gray-700 rounded-md px-6 py-2 block hover:bg-primary-700">
                  Login
                </button>

              </div>
            </form>
          </div>
          <div className="mx-auto max-w-md text-center">
            <label className="block">
              <Link to="/forgot-password" className="cursor-pointer text-primary font-medium hover:underline">
                <span>Forgot Password?</span>
              </Link>
            </label>
            <div className="flex justify-center items-center my-8">
              <p>Don’t have an account?</p>
              <Link to="/register" className="cursor-pointer text-primary font-medium ml-2 hover:underline" >
                Register
              </Link>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default LoginForm;
